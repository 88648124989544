<template>
  <div style="background: #fff" :class="disabled ? 'disableGray' : ''">
    <van-notice-bar
      v-if="tipTxt"
      left-icon="info-o"
      wrapable
      :scrollable="false"
      :text="tipTxt"
    />

    <div class="free-header">
      <img
        src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/extendInfo/student.png"
        width="100%"
        height="auto"
      />
    </div>
    <van-form @submit="onSubmit" :disabled="disabled">
      <div class="all-contaions">
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">个人信息</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('firstName')"
            v-model="infoFrom.firstName"
            required
            :rules="[{ required: true }]"
            label="姓"
            placeholder="请输入你的姓氏"
          />
          <van-field
            :left-icon="failIcon('lastName')"
            v-model="infoFrom.lastName"
            required
            :rules="[{ required: true }]"
            label="名"
            placeholder="请输入你的名称"
          />
          <van-field
            :left-icon="failIcon('oldName')"
            v-model="infoFrom.oldName"
            :rules="[{ required: false }]"
            label="曾用名"
            placeholder="请输入你的曾用名"
          />
          <!-- 性别 -->
          <div class="smoke-flex student">
            <div class="smoke-flex-left grayColor">
              <span>*</span>
              <van-icon :name="failIcon('sex')" />
              性别
            </div>
            <van-radio-group
              v-model="infoFrom.sex"
              direction="horizontal"
              :disabled="disabled"
            >
              <van-radio name="1" checked-color="#f67f58">男</van-radio>
              <van-radio name="2" checked-color="#f67f58">女</van-radio>
              <van-radio name="3" checked-color="#f67f58">不确定</van-radio>
            </van-radio-group>
          </div>

          <!-- 国籍 -->
          <van-field
            :left-icon="failIcon('nationality')"
            name="picker"
            :value="infoFroms.nationalName"
            readonly
            required
            :rules="[{ required: true }]"
            label="国籍"
            placeholder="点击选择国籍"
            @click="isNational = !disabled"
            right-icon="arrow-down"
          />
          <van-popup v-model="isNational" position="bottom">
            <van-picker
              show-toolbar
              :columns="nationalOption"
              @confirm="onConfirmNational"
              @cancel="isNational = false"
            />
          </van-popup>
          <!-- 出生日期		 -->
          <van-field
            :left-icon="failIcon('birthDay')"
            :value="infoFrom.birthDay"
            required
            readonly
            :rules="[{ required: true }]"
            label="出生日期"
            placeholder="请选择你的出生日期"
            @click="isShow = !disabled"
            right-icon="notes-o"
          />
          <van-popup v-model="isShow" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="confirm"
              @cancel="isShow = false"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('phone')"
            v-model="infoFrom.phone"
            type="tel"
            required
            :rules="[
              { required: true },
              { pattern: /^[0-9]*$/, message: '手机号码格式错误！' },
            ]"
            label="手机号码"
            placeholder="请填写你的手机号码"
          />
          <van-field
            :left-icon="failIcon('wechat')"
            v-model="infoFrom.wechat"
            type="text"
            required
            :rules="[{ required: true }]"
            label="微信号"
            placeholder="请填写你的微信号"
          />
          <van-field
            :left-icon="failIcon('email')"
            v-model="infoFrom.email"
            type="text"
            required
            :rules="[
              { required: true },
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            label="邮箱"
            placeholder="请填写你的邮箱"
          />
          <!-- 护照号 -->
          <van-field
            :left-icon="failIcon('passport')"
            v-model="infoFrom.passport"
            required
            :rules="[{ required: true }]"
            label="护照号"
            placeholder="请填写你的护照号码"
          />
          <van-field
            :left-icon="failIcon('expiryDayOfPassport')"
            :value="infoFrom.expiryDayOfPassport"
            readonly
            required
            :rules="[{ required: true }]"
            label="护照过期日"
            placeholder="请选择你的护照过期日"
            @click="isPassport = !disabled"
            right-icon="notes-o"
          />
          <van-popup v-model="isPassport" position="bottom">
            <van-datetime-picker
              type="date"
              @confirm="confirmPass"
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="isPassport = false"
            />
          </van-popup>
          <!-- 签证号 -->
          <van-field
            :left-icon="failIcon('visa')"
            v-model="infoFrom.visa"
            required
            :rules="[{ required: true }]"
            label="签证号"
            placeholder="请填写你的签证号"
          />
          <van-field
            :left-icon="failIcon('expiryDayOfVisa')"
            :value="infoFrom.expiryDayOfVisa"
            readonly
            required
            :rules="[{ required: true }]"
            label="签证过期日"
            placeholder="请选择你的签证过期日"
            @click="isExpiryDayOfVisa = !disabled"
            right-icon="notes-o"
          />
          <van-popup v-model="isExpiryDayOfVisa" position="bottom">
            <van-datetime-picker
              type="date"
              @confirm="confirmVisa"
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="isExpiryDayOfVisa = false"
            />
          </van-popup>
          <!-- 入住是否满18岁 -->
          <van-field
            :left-icon="failIcon('whetherAdult')"
            name="radio"
            label-width="150px"
            label="你入住时满18岁了吗？"
            required
            :rules="[{ required: true }]"
          >
            <template #input>
              <van-radio-group
                v-model="infoFrom.whetherAdult"
                direction="horizontal"
                :disabled="disabled"
              >
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell-group>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">现居住的地址</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-cell
            :icon="failIcon('nowAddress')"
            class="grayColor"
            title="详细地址"
            required
          />
          <van-field
            v-model="infoFrom.nowAddress"
            :rules="[{ required: true }]"
            rows="1"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="请输入现居住的详细地址"
            show-word-limit
          />
        </van-cell-group>
        <!-- 何时搬入 -->
        <van-field
          :left-icon="failIcon('moveInTime')"
          v-model="infoFrom.moveInTime"
          label-width="120px"
          readonly
          required
          :rules="[{ required: true }]"
          label="你何时搬入的？"
          placeholder="请选择你的搬入时间"
          @click="isMove = !disabled"
          right-icon="notes-o"
        />
        <van-popup v-model="isMove" position="bottom">
          <van-datetime-picker
            type="date"
            v-model="currentDate"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="confirmMove"
            @cancel="isMove = false"
          />
        </van-popup>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">学校信息</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('schoolName')"
            v-model="infoFrom.schoolName"
            required
            :rules="[{ required: true }]"
            label="学校"
            placeholder="请填写你的学校"
          />
          <van-field
            :left-icon="failIcon('majorName')"
            v-model="infoFrom.majorName"
            required
            :rules="[{ required: true }]"
            label="专业名称"
            placeholder="请填写你的专业名称"
          />
          <!-- 入学年级 -->
          <van-field
            :left-icon="failIcon('yearOfStudy')"
            :value="infoFroms.yearOfStudy"
            readonly
            required
            :rules="[{ required: true }]"
            label="入学年级"
            placeholder="请选择入学年级"
            @click="isGrade = !disabled"
            right-icon="notes-o"
          />
          <van-popup v-model="isGrade" position="bottom">
            <van-picker
              show-toolbar
              :columns="gradeOption"
              @confirm="onConfirmGrade"
              @cancel="isGrade = false"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('studentNo')"
            v-model="infoFrom.studentNo"
            label="学生证件号"
            placeholder="请填写你的学生证件号"
          />
        </van-cell-group>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">紧急联系人</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('contactsFirstName')"
            v-model="infoFrom.contactsFirstName"
            required
            :rules="[{ required: true }]"
            label="姓"
            placeholder="请输入联系人的姓氏"
          />
          <van-field
            :left-icon="failIcon('contactsLastName')"
            v-model="infoFrom.contactsLastName"
            required
            :rules="[{ required: true }]"
            label="名"
            placeholder="请输入联系人的名称"
          />
          <!-- 与联系人关系 -->
          <van-field
            :left-icon="failIcon('contactsRelationship')"
            :value="infoFroms.contactShipName"
            readonly
            required
            :rules="[{ required: true }]"
            label="关系"
            @click="isShip = !disabled"
            placeholder="请选择与你的关系"
          />
          <van-popup v-model="isShip" position="bottom">
            <van-picker
              show-toolbar
              :columns="proverOption"
              @confirm="onConfirmContact"
              @cancel="isShip = false"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('contactsPhone')"
            v-model="infoFrom.contactsPhone"
            type="tel"
            required
            :rules="[
              { required: true },
              { pattern: /^[0-9]*$/, message: '手机号码格式错误！' },
            ]"
            label="手机号码"
            placeholder="请填写联系人的手机号码"
          />
          <van-field
            :left-icon="failIcon('contactsEmail')"
            v-model="infoFrom.contactsEmail"
            type="text"
            required
            :rules="[
              { required: true },
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            label="邮箱"
            placeholder="请填写联系人的邮箱"
          />
        </van-cell-group>
        <van-cell-group>
          <van-cell
            :icon="failIcon('contactsAddress')"
            class="grayColor"
            title="现居住地址"
          />
          <van-field
            v-model="infoFrom.contactsAddress"
            :rules="[{ required: false }]"
            rows="1"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="请输入联系人现居住的详细地址"
            show-word-limit
          />
        </van-cell-group>
        <div class="message">
          如遇到问题请至邮件info@clozhome或您的顾问获取帮助
        </div>
      </div>
      <div class="spacing-height"></div>
      <div class="bottom-flex" v-if="!disabled">
        <div class="flex-left">
          <div class="left-consult" @click="serviceClick">
            <van-image
              width="23"
              height="20"
              fit="scale-down"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/consult.png"
            /><br />咨询客服
          </div>
        </div>
        <div class="flex-right">
          <van-button v-if="disabled" class="default" type="default"
            >立即提交</van-button
          >
          <van-button
            v-if="!disabled"
            class="primary"
            type="primary"
            native-type="submit"
            >立即提交</van-button
          >
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  addUpdateApply,
  getUpdateApplyStatus,
  getProductUpdateInfo,
} from "@/api/informations/information";
import { timeStampChange, transform } from "@/utils/index";
import Data from "@/utils/options";
export default {
  name: "StudentApartment",
  data() {
    return {
      infoFrom: {
        whetherAdult: "1",
      },
      infoFroms: {},
      product: {},
      disabled: false,
      nationalOption: Data.nationalOption(),
      proverOption: Data.proverOption(),
      gradeOption: Data.gradeOption(),
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2080, 10, 1),
      currentDate: new Date(),
      valueNational: undefined,
      isShow: false,
      isNational: false,
      isSexShow: false,
      isShip: false,
      isPassport: false,
      isExpiryDayOfVisa: false,
      isMove: false,
      isStart: false,
      isGrade: false,
      isEnd: false,
      processId: undefined,
      env: "",
      tipTxt: "",
      failLabels: [],
    };
  },
  created() {
    this.processId = this.$route.query.processId;
    this.onload();
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.getEnv(function (res) {
          that.env = res;
          console.log("当前环境：" + JSON.stringify(res));
        });
      });
    });
  },
  methods: {
    failIcon(label) {
      for (let i = 0; i < this.failLabels.length; i++) {
        if (this.failLabels[i] == label) {
          return "info-o";
        }
      }
      return "";
    },
    //验证邮箱
    validator(val) {
      if (!val) return true;
      if (val.trim().length == 0) return true;
      return /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
        val
      );
    },
    //验证邮箱...
    onload() {
      // this.disabled = true;
      getUpdateApplyStatus({ processId: this.processId }).then((res) => {
        if (res.data) {
          if (res.data.status == 1) {
            if (res.data.failItems) {
              //被驳回了
              this.disabled = false;
              this.tipTxt =
                "您好，您提供的补充申请信息中" +
                this.assemblyTip(res.data.failItems) +
                "信息有误,请确认信息修改后重新提交!";
            } else {
              this.disabled = true;
            }
            this.infoFrom = JSON.parse(res.data.updateInfo);
            let data = this.infoFrom;
            this.infoFroms.nationalName = transform(
              this.nationalOption,
              data.nationality
            );
            this.infoFroms.contactShipName = transform(
              this.proverOption,
              data.contactsRelationship
            );
            this.infoFroms.yearOfStudy = transform(
              this.gradeOption,
              data.yearOfStudy
            );
          }
          this.getProductUpdateInfo(res.data.productId);
          return;
        }
      });
    },
    //组装驳回字段,超过3个...
    assemblyTip(failItems) {
      let txtAry = [];
      if (failItems) {
        let itemsAry = failItems.split(",");
        itemsAry.map((it) => {
          let item = it.split("=");
          this.failLabels.push(item[0]);
          txtAry.push(item[1]);
        });
      }
      if (txtAry.length > 3) {
        return txtAry[0] + "," + txtAry[1] + "," + txtAry[2] + "...";
      }
      return txtAry.join(",");
    },
    getProductUpdateInfo(productId) {
      getProductUpdateInfo({ productId: productId }).then((res) => {
        this.product = res.data;
      });
    },
    confirm(data) {
      this.infoFrom.birthDay = timeStampChange(data);
      this.isShow = false;
    },
    onConfirmNational(value, index) {
      this.infoFroms.nationalName = value;
      this.infoFrom.nationality = index + 1;
      this.isNational = false;
    },
    // 年级选项
    onConfirmGrade(value, index) {
      this.infoFroms.yearOfStudy = value;
      this.infoFrom.yearOfStudy = index + 1;
      this.isGrade = false;
    },
    onConfirmContact(value, index) {
      this.infoFroms.contactShipName = value;
      this.infoFrom.contactsRelationship = index + 1;
      this.isShip = false;
    },
    confirmPass(data) {
      this.infoFrom.expiryDayOfPassport = timeStampChange(data);
      this.isPassport = false;
    },
    confirmVisa(data) {
      this.infoFrom.expiryDayOfVisa = timeStampChange(data);
      this.isExpiryDayOfVisa = false;
    },
    confirmMove(data) {
      this.infoFrom.moveInTime = timeStampChange(data);
      this.isMove = false;
    },
    confirmStart(data) {
      this.infoFrom.startTime = timeStampChange(data);
      this.isStart = false;
    },
    confirmEnd(data) {
      this.infoFrom.endTime = timeStampChange(data);
      this.isEnd = false;
    },
    serviceClick() {
      console.log("点击联系客服===>");
      if (this.env.miniprogram) {
        uni.navigateTo({
          url: "/otherPages/webview/showContact",
        });
      } else {
        enterMeiQiaService.postMessage(
          JSON.stringify({
            serviceName: this.product.productName,
            applyType: this.product.updateApplyName,
          })
        );
      }
    },
    // 提交校验
    onSubmit() {
      var that = this;
      if (this.disabled) {
        return;
      }
      let obj = {
        updateApplyJson: JSON.stringify(this.infoFrom),
        processId: this.processId,
      };
      console.log("提交补充表单===>", this.infoFrom);
      addUpdateApply(obj).then((res) => {
        console.log("提交补充表单结果===>", res);
        if (res.code == 0) {
          var obj2 = JSON.stringify({
            orderNo: res.data.orderNo,
            orderTime: res.data.orderTime,
            recordId: res.data.recordId,
          });
          var obj3 = encodeURIComponent(obj2);
          // if (that.env.miniprogram) {
          //   uni.navigateTo({
          //     url: `/applyPages/extendInfo?orders=${obj3}`,
          //   });
          // } else {
          //   enterApplyResult.postMessage(
          //     JSON.stringify({
          //       orderNo: res.data.orderNo,
          //       orderTime: res.data.orderTime,
          //       recordId: res.data.recordId,
          //     })
          //   );
          // }
          try {
            enterApplyResult.postMessage(
              JSON.stringify({
                orderNo: res.data.orderNo,
                orderTime: res.data.orderTime,
                recordId: res.data.recordId,
              })
            );
          } catch (error) {
            uni.navigateTo({
              url: `/applyPages/extendInfo?orders=${obj3}`,
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.van-icon-info-o {
  color: #ed6a0c;
  font-size: 16px !important;
}
.bottom-flex {
  padding-top: 10px;
  padding-bottom: 10px;
}
/deep/.van-icon-notes-o::before {
  font-size: 22px;
}
/deep/.van-button {
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
/deep/.van-radio__icon--checked .van-icon {
  background-color: #f67f58;
  border-color: #f67f58;
}
/deep/.van-radio--horizontal {
  margin-top: 10px;
}
.flex-right {
  border-radius: 4px;
  overflow: hidden;
}
.disableGray {
  .grayColor {
    color: #c8c9cc;
  }
}
</style>
